<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card class="SearchBox">
      <el-row>
        <el-form :model="searchFormData" :inline="true">
          <el-form-item label="所属门店" prop="shopId">
            <el-select v-model="searchFormData.shopId" placeholder="请选择" @change="handleChanged">
              <el-option v-for="item in shopDrops" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="pageData" style="width: 100%" show-summary :summary-method="getSummaries">
        <el-table-column prop="title" label="产品名称" />
        <el-table-column prop="checkCount" label="核销数量" />
        <el-table-column prop="totalCount" label="总计数量" />
        <el-table-column prop="totalCount" label="核销率">
          <template slot-scope="scope">
            <span>{{(scope.row.checkCount/scope.row.totalCount*100).toFixed(4)+'%'}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getCheckStatistic } from '@/service/statistics.js';
import { dropShop } from '@/service/shop.js';
export default {
  data() {
    return {
      shopDrops: [
        {
          label: '--全部--',
          value: 0,
        }
      ],
      loading: false,
      searchFormData: {
        shopId: 0,
      },
      pageData: []
    }
  },
  created() {
    this.DataBinding();
  },
  methods: {
    //加载数据
    DataBinding() {
      this.DropStores();
      getCheckStatistic(this.searchFormData).then(res => {
        console.log(res);
        this.pageData = res.data;
      })
    },
    //获取门店下拉框数据
    DropStores() {
      dropShop().then(res => {
        this.shopDrops = [{
          label: '--全部--',
          value: 0,
        }];
        if (res.code == 0) {
          res.data.forEach(element => {
            this.shopDrops.push(element);
          });
        }
      });
    },
    // 表格合计行计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        if (index == 1 || index == 2) {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
        if (index == 3) {
          sums[index] = (sums[1] / sums[2] * 100).toFixed(4) + '%';
        }

      });

      return sums;
    },
    //选中门店
    handleChanged(shopId) {
      this.DataBinding();
    }
  }
}

</script>
<style scoped>
.SearchBox {
  margin-bottom: 30px;
}
</style>